import { Banner } from '../components/Banner';
import { Modalities } from '../components/Modalities';
import { Integrations } from '../components/Integrations';
import { SwimmingSchedule } from '../components/SwimmingSchedule';
import { Form } from '../components/Form';
import { SwimingInformation } from '../components/SwimmingInformation';

export function Home() {
    return (
        <>
            <Banner />
            <Modalities />
            <Integrations />
            <SwimmingSchedule />
            <SwimingInformation />
            <Form />
        </>
    );
}
