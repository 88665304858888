import { useEffect } from "react";
import bannerImg from "../assets/musculacao2.jpeg";
import { Others } from "../components/Modalities/Others";
import styles from "./about.module.scss";

export function Musculacao() {
    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }, []);
    return (
        <main className={styles.main}>
            <article>
                <h1 className={styles.title}>Musculação</h1>
                <span className={styles.subtitle}>a partir dos 15 anos</span>
                <img
                    src={bannerImg}
                    alt='musculação'
                    className={styles.banner}
                />
                <p>
                    A musculação é um tipo de exercício realizado com pesos de
                    diversas cargas, amplitude e tempo de contração, o que faz
                    dela uma atividade física indicada para pessoas de diversas
                    idades e com diferentes objetivos. É diferente do
                    fisiculturismo e levantamento de peso, pois esses são
                    esportes e a musculação é uma modalidade de exercício.
                </p>
                <p>
                    A musculação é uma modalidade que sempre está em alta, além
                    de ajudar a queimar gordura antes e após o treino, trabalha
                    os músculos e modela o corpo deixando-o mais firme e bonito.
                </p>
                <p>
                    O treinamento com pesos auxilia no emagrecimento, aumenta o
                    gasto calórico diário e estimula o metabolismo. Previne a
                    osteoporose, por estimular a produção de massa óssea. Pode
                    evitar o diabetes, já que o aumento de massa muscular queima
                    mais glicose.
                </p>
                <p>
                    Em pessoas idosas, esses exercícios com peso interrompem a
                    perda de mobilidade e a atrofia muscular, diminuindo a
                    pressão arterial e a frequência cardíaca.
                </p>
                <h3>Benefícios da Musculação</h3>
                <ul>
                    <li>Aumenta e define a massa muscular.</li>
                    <li>
                        Ajuda a rejuvenescer. A partir dos 30 anos, começamos a
                        perder massa muscular; podendo chegar a uma perda de até
                        30% de massa magra até os 80 anos. Quem faz musculação
                        retarda o processo de envelhecimento.
                    </li>
                    <li>
                        Emagrece. Todo tipo de exercício ajuda na perda de peso,
                        pois em todos há um gasto calórico. Em longo prazo, os
                        exercícios com peso apresentam o importante papel de
                        aumentar a taxa metabólica basal.
                    </li>
                    <li>
                        Melhora o sono e o bem estar. Melhora a postura. A
                        maioria dos casos de dores nas costas está relacionada à
                        fraqueza muscular e à falta de flexibilidade. O trabalho
                        com peso torna os músculos (que sustentam os ossos) mais
                        resistentes.
                    </li>
                    <li>
                        Ajuda a manter nossa força funcional para atividades
                        cotidianas e esportes.
                    </li>
                    <li>
                        Ajuda a aliviar os sintomas de artrite ao fortalecer os
                        músculos que dão suporte às nossas articulações.
                    </li>
                    <li>Aumenta a densidade óssea.</li>
                    <li>
                        Melhora sua autoestima, refletindo positivamente na vida
                        afetiva e social.
                    </li>
                    <li>
                        Atua na prevenção da osteoporose, artrose e diabetes.
                    </li>
                </ul>
            </article>

            <Others />
        </main>
    );
}
