import { useEffect } from "react";
import bannerImg from "../assets/natacao2.jpeg";
import { Others } from "../components/Modalities/Others";
import styles from "./about.module.scss";

export function Natacao() {
    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }, []);
    return (
        <main className={styles.main}>
            <article>
                <h1 className={styles.title}>Natação na SEMA</h1>
                <img
                    src={bannerImg}
                    alt='musculação'
                    className={styles.banner}
                />
                <h2>Natação Bebê</h2>
                <span className={styles.subtitle}>
                    a partir dos 06 meses - 40 minutos de aula
                </span>
                <p>
                    As aulas de natação para bebês proporcionam muitos
                    benefícios, como auxiliar e estimular o desenvolvimento
                    motor e cognitivo. No entanto, alguns pais ainda têm dúvidas
                    sobre quando colocar os filhos na piscina e quais são esses
                    benefícios.
                </p>
                <h3>Quando o bebê pode começar?</h3>
                <p>
                    A natação para bebês é uma atividade que pode ser iniciada
                    bem cedo. Até cerca de 1 ano de idade, os bebês ainda têm o
                    reflexo natatório (ou de apneia): debaixo da água, os bebês
                    fecham automaticamente a boca, bloqueiam a respiração e
                    mexem os braços e as pernas. Por isso, é recomendada
                    <strong> a partir de 6 meses de idade</strong>, quando o
                    bebê já tomou as principais vacinas e o duto do ouvido está
                    bem desenvolvido, diminuindo o risco de infecções. Quanto
                    mais cedo o bebê entrar na piscina, certamente, mais
                    facilidade ele terá para nadar. O reflexo natatório é mais
                    forte no primeiro ano de vida. Até os 2 anos, a presença de
                    alguém de confiança do bebê, principalmente a
                    <strong> mãe ou o pai</strong>, é fundamental, pois, durante
                    as aulas, é estimulado o vínculo entre eles.
                </p>
                <h3>
                    E quais são os benefícios das aulas de natação para bebês?
                </h3>
                <ul>
                    <li>
                        Proporcionar noção de lateralidade e direção
                        psicomotricidade ampla e fina;
                    </li>
                    <li>Noção viso espacial;</li>
                    <li>
                        Habilidades fundamentais no desenvolvimento intelectual
                        da criança;
                    </li>
                    <li>Socialização;</li>
                    <li>Adaptação ao meio líquido;</li>
                    <li>Melhorar a postura;</li>
                    <li>Melhorar o condicionamento respiratório;</li>
                    <li>Melhorar a qualidade do sono;</li>
                    <li>Estimular o apetite;</li>
                    <li>Relaxar.</li>
                </ul>
                <p>
                    <strong>Vale destacar:</strong> quanto mais cedo começar,
                    maior será o repertório motor e emocional do bebê, o que
                    auxiliará em um crescimento saudável. Portanto, você terá
                    uma criança mais feliz.
                </p>
            </article>

            <article>
                <h2>Natação Infantil e Adulto</h2>
                <span className={styles.subtitle}>
                    a partir dos 07 anos - 50 minutos de aula
                </span>
                <p>
                    A natação é uma atividade física baseada na capacidade
                    humana de se locomover na água (nadar). É um dos esportes
                    mais praticados em todo o mundo. Além do condicionamento
                    físico, a natação traz diversos benefícios para a saúde,
                    possuindo adeptos de todas as idades.
                </p>
                <br />
                <p>
                    Considerada um dos exercícios mais completos por movimentar
                    grande parte dos músculos e articulações do corpo. Oferece
                    vários benefícios como: liberação de tensões, resistência
                    muscular, diminuição do estresse, do colesterol e da pressão
                    arterial e melhora a circulação sanguínea. Costuma ser muito
                    indicada com objetivos terapêuticos, no tratamento de
                    problemas respiratórios e recuperação de atrofias
                    musculares.
                </p>
                <br />
                <p>
                    O risco de lesões que a natação oferece é pequeno, já que a
                    água amortece os impactos. É essencial adotar uma dieta
                    equilibrada para suprir as necessidades do corpo, pois a
                    natação é um exercício que requer muita energia.
                </p>
                <h3>A natação se divide em quatro estilos:</h3>
                <ul>
                    <li>
                        Crawl - braçadas alternadas e movimento vertical, também
                        alternado;
                    </li>
                    <li>
                        Costas - Movimentos de braços e pernas alternado como no
                        crawl, mas de costas para fundo da piscina;
                    </li>
                    <li>
                        Peito - na posição de bruços, o atleta projeta o corpo
                        para fora da piscina e realiza um movimento de braços e
                        pernas em conjunto,
                    </li>
                    <li>
                        Borboleta - Movimento das pernas ondulatório como o nado
                        peito, mas com o movimento de braços simultâneos,
                        projetados para fora da piscina.
                    </li>
                </ul>
            </article>

            <Others />
        </main>
    );
}
