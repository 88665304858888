import styles from './about.module.scss';

export function About() {
    return (
        <main className={styles.main}>
            <h1 className={styles.title}>Modalidades da SEMA</h1>
            <article>
                <h2>Natação Bebê</h2>
                <span>a partir dos 06 meses - 40 minutos de aula</span>

                <p>
                    As aulas de natação para bebês proporcionam muitos
                    benefícios, como auxiliar e estimular o desenvolvimento
                    motor e cognitivo. No entanto, alguns pais ainda têm dúvidas
                    sobre quando colocar os filhos na piscina e quais são esses
                    benefícios.
                </p>
                <h3>Quando o bebê pode começar?</h3>
                <p>
                    A natação para bebês é uma atividade que pode ser iniciada
                    bem cedo. Até cerca de 1 ano de idade, os bebês ainda têm o
                    reflexo natatório (ou de apneia): debaixo da água, os bebês
                    fecham automaticamente a boca, bloqueiam a respiração e
                    mexem os braços e as pernas. Por isso, é recomendada
                    <strong> a partir de 6 meses de idade</strong>, quando o
                    bebê já tomou as principais vacinas e o duto do ouvido está
                    bem desenvolvido, diminuindo o risco de infecções. Quanto
                    mais cedo o bebê entrar na piscina, certamente, mais
                    facilidade ele terá para nadar. O reflexo natatório é mais
                    forte no primeiro ano de vida. Até os 2 anos, a presença de
                    alguém de confiança do bebê, principalmente a
                    <strong> mãe ou o pai</strong>, é fundamental, pois, durante
                    as aulas, é estimulado o vínculo entre eles.
                </p>
                <h3>
                    E quais são os benefícios das aulas de natação para bebês?
                </h3>
                <ul>
                    <li>
                        Proporcionar noção de lateralidade e direção
                        psicomotricidade ampla e fina;
                    </li>
                    <li>Noção viso espacial;</li>
                    <li>
                        Habilidades fundamentais no desenvolvimento intelectual
                        da criança;
                    </li>
                    <li>Socialização;</li>
                    <li>Adaptação ao meio líquido;</li>
                    <li>Melhorar a postura;</li>
                    <li>Melhorar o condicionamento respiratório;</li>
                    <li>Melhorar a qualidade do sono;</li>
                    <li>Estimular o apetite;</li>
                    <li>Relaxar.</li>
                </ul>
                <p>
                    <strong>Vale destacar:</strong> quanto mais cedo começar,
                    maior será o repertório motor e emocional do bebê, o que
                    auxiliará em um crescimento saudável. Portanto, você terá
                    uma criança mais feliz.
                </p>
            </article>

            <article>
                <h2>Natação Infantil e Adulto</h2>
                <span>a partir dos 07 anos - 50 minutos de aula</span>
                <p>
                    A natação é uma atividade física baseada na capacidade
                    humana de se locomover na água (nadar). É um dos esportes
                    mais praticados em todo o mundo. Além do condicionamento
                    físico, a natação traz diversos benefícios para a saúde,
                    possuindo adeptos de todas as idades.
                </p>
                <p>
                    Considerada um dos exercícios mais completos por movimentar
                    grande parte dos músculos e articulações do corpo. Oferece
                    vários benefícios como: liberação de tensões, resistência
                    muscular, diminuição do estresse, do colesterol e da pressão
                    arterial e melhora a circulação sanguínea. Costuma ser muito
                    indicada com objetivos terapêuticos, no tratamento de
                    problemas respiratórios e recuperação de atrofias
                    musculares.
                </p>
                <p>
                    O risco de lesões que a natação oferece é pequeno, já que a
                    água amortece os impactos. É essencial adotar uma dieta
                    equilibrada para suprir as necessidades do corpo, pois a
                    natação é um exercício que requer muita energia.
                </p>
                <h3>A natação se divide em quatro estilos:</h3>
                <ul>
                    <li>
                        Crawl - braçadas alternadas e movimento vertical, também
                        alternado;
                    </li>
                    <li>
                        Costas - Movimentos de braços e pernas alternado como no
                        crawl, mas de costas para fundo da piscina;
                    </li>
                    <li>
                        Peito - na posição de bruços, o atleta projeta o corpo
                        para fora da piscina e realiza um movimento de braços e
                        pernas em conjunto,
                    </li>
                    <li>
                        Borboleta - Movimento das pernas ondulatório como o nado
                        peito, mas com o movimento de braços simultâneos,
                        projetados para fora da piscina.
                    </li>
                </ul>
            </article>
            <article>
                <h2>Hidroginástica</h2>
                <span>a partir dos 15 anos - 50 minutos de aula</span>
                <p>
                    Uma modalidade física onde a ausência da gravidade diminui o
                    risco de contusão, proporcionando bem-estar ao praticante. A
                    Hidroginástica é a principal modalidade para terceira idade
                    e pessoas em recuperação motora.
                </p>
                <p>
                    Os movimentos realizados pelos alunos são feitos justamente
                    contra a força exercida pela água que é de baixo para cima.
                    Essa forma de sobrecarga pode contar com materiais para
                    aumentar a resistência, como halter, aquatubo ou macarrão,
                    caneleira, luva, colete, bola, prancha ou qualquer outro
                    material aquático. A música é o fator principal durante a
                    atividade, já que os alunos estão lá muitas vezes para
                    socializar e se divertir. O ritmo proporciona o estímulo
                    necessário para os praticantes.
                </p>
                <p>
                    Os benefícios da hidroginástica são muitos porque incluem
                    todos aqueles de atividades físicas em meio terrestre
                    (aeróbios e/ou localizados), aliados ao impacto quase
                    inexistente sobre as articulações. Apresenta também melhoras
                    para os diversos males como a constipação, depressão,
                    estresse, insônia, osteoporose, pressão alta e senilidade.
                    Então se apresenta como uma ótima possibilidade de prática,
                    não apenas para os idosos, mas sim para todas as pessoas. O
                    gasto calórico médio é de 260 a 400 Kcal/hora.
                </p>
                <h3>Principais Benefícios:</h3>
                <ul>
                    <li>
                        Exercícios aeróbicos e cardiovasculares realizados em
                        ambiente aquático acompanhado de música.
                    </li>
                    <li>Turmas de todas as idades.</li>
                    <li>
                        Trabalha o fortalecimento muscular e contribui para a
                        diminuição do percentual de gordura.
                    </li>
                    <li>
                        Melhora o sistema cardiovascular: o trabalho na aula de
                        hidroginástica auxilia no condicionamento por ter um
                        aumento do retorno venoso (maior volume de sangue
                        voltando ao coração para ser bombeado). - Até por esse
                        motivo, ela é indicada por cardiologistas para pessoas
                        hipertensas ou com outros problemas cardíacos.
                    </li>
                    <li>
                        Auxilia na drenagem linfática, reduzindo a retenção de
                        líquidos e sendo grande aliada do emagrecimento.
                    </li>
                    <li>
                        Melhora a flexibilidade: a redução da força
                        gravitacional na água proporciona uma maior e melhor
                        flexibilidade do corpo.
                    </li>
                    <li>Melhora a qualidade de vida</li>
                    <li>Melhora a qualidade do sono</li>
                    <li>Relaxa o corpo após um dia repleto de atividades.</li>
                </ul>
            </article>
        </main>
    );
}
