import { Route, Routes } from 'react-router-dom';
import { Home } from './screens/home';
import { About } from './screens/about';
import { Header } from './components/Header';
import { Footer } from './components/Footer';

import { Natacao } from './screens/natacao';
import { Musculacao } from './screens/musculacao';
import { Circuito } from './screens/circuito';
import { Hidro } from './screens/hidroginastica';
import { Funcional } from './screens/funcional';
import { Aerobox } from './screens/aerobox';

import './global.scss';

function App() {
    return (
        <>
            <Header />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='about' element={<About />} />
                <Route path='musculacao' element={<Musculacao />} />
                <Route path='natacao' element={<Natacao />} />
                <Route path='circuito' element={<Circuito />} />
                <Route path='hidro' element={<Hidro />} />
                <Route path='funcional' element={<Funcional />} />
                <Route path='aerobox' element={<Aerobox />} />
            </Routes>
            <Footer />
        </>
    );
}

export default App;
