import Banner1 from '../../assets/banner-1.jpg';
import styles from './styles.module.scss';

export function Banner() {
    return (
        <div className={styles.bannerContiner}>
            <img src={Banner1} alt='Banner' />
            <h1>
                Academia <br />
                <span>SEMA</span>
            </h1>
        </div>
    );
}
