import { useEffect } from "react";
import bannerImg from "../assets/aerobox2.jpg";
import { Others } from "../components/Modalities/Others";
import styles from "./about.module.scss";

export function Aerobox() {
    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }, []);

    return (
        <main className={styles.main}>
            <article>
                <h1 className={styles.title}>Aeroboxe</h1>
                <img
                    src={bannerImg}
                    alt='musculação'
                    className={styles.banner}
                    style={{ objectPosition: "50% 10%" }}
                />
                <p>
                    O intenso trabalho aeróbico obtido através deste exercício
                    permite não só um aumento mais rápido da força muscular,
                    como também ajuda a melhorar a flexibilidade e favorece a
                    perda de peso. O segredo do Aeroboxe é colocar em ação, num
                    mesmo treino, todos os movimentos e benefícios de duas
                    disciplinas: a aeróbica e o boxe. Este tipo de treino é
                    muito completo e fácil de combinar com a sua vida diária.
                </p>
                <p>
                    Praticar Aeroboxe por uma hora promove a redução de medidas
                    corporais. Os movimentos ritmados do esporte definem membros
                    superiores e inferiores. Além disso, desenvolvem a
                    musculatura dos braços e das pernas, tonifica o abdômen,
                    melhora a flexibilidade, a coordenação motora, a agilidade e
                    a concentração.
                </p>
                <p>
                    Os inúmeros benefícios vão muito além da estética e também
                    favorecem a saúde dos praticantes. E mais, o Aeroboxe também
                    é considerado um dos melhores exercícios para reduzir o
                    estresse e liberar a tensão do dia a dia.
                </p>
                <p>
                    Em um treino, o corpo todo pode ser trabalhado com
                    diferentes exercícios que combinam socos e chutes de
                    Kickboxing, aliado a uma atividade aeróbica. Como os
                    movimentos são rápidos e ritmados, há uma forte descarga de
                    energia. Geralmente uma hora deste exercício costuma queimar
                    cerca de 600 calorias.
                </p>
            </article>

            <Others />
        </main>
    );
}
