import React from 'react';
import styles from './styles.module.scss';

export function SwimingInformation() {
    return (
        <section className={styles.infoContainer}>
            <div className={styles.card}>
                <h2>Atenção!</h2>
                <p>
                    Pais ou responsável, que irão acompanhar os bebês nas aulas,
                    sempre devem estar prevenidos com os trajes obrigatórios
                    para entrar na água, caso seja necessário ou solicitado pelo
                    professor.
                </p>
            </div>
            <div className={styles.card}>
                <h2>Trajes obrigatórios para natação</h2>
                <p>Meninas: Maiô ou macaquinho e touca</p>
                <p>Meninos: Sunga e touca</p>
                <span>
                    Óculos não é obrigatório, porém indicamos o uso devido ao
                    cloro
                </span>
            </div>
        </section>
    );
}
