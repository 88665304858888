import { BsLightningChargeFill } from 'react-icons/bs';
import styles from './styles.module.scss';

export function About() {
    return (
        <div className={styles.aboutContainer}>
            <h1>Sobre a SEMA</h1>
            <p>
                Fundada em 16 de março de 1994 no bairro de Guaianases, a Sema
                Natação foi a primeira escola de esportes aquáticos da região.
                Inicialmente, atendia apenas demandas como a natação e a
                hidroginástica, mas com a dedicação de seus colaboradores, o
                aprimoramento dos serviços alinhado a qualidade dos
                profissionais, sentiu a necessidade de ampliar os horizontes e
                passou por uma reformulação no ano de 2001, nascendo assim, a
                Academia Sema, que além de esportes aquáticos, agora também
                possui a musculação, circuito, aeroboxe e treino funcional em
                seu portfólio.
            </p>
            <div className={styles.subContent}>
                <h2>Quais seus objetivos?</h2>
                <p>
                    Em cinco anos operar processos de atendimento voltado para
                    alunos com doenças crônicas e em 10 anos, estender esse
                    atendimento para uma equipe com profissionais
                    multidisciplinares.
                </p>
            </div>
            <div className={styles.subContent}>
                <h2>O que ela oferece?</h2>
                <ul>
                    <li>
                        <span>
                            <BsLightningChargeFill color='#46b4e6' />
                            Aulas de natação de bebês (a partir dos 06 meses) e
                            adultos;
                        </span>
                    </li>
                    <li>
                        <span>
                            <BsLightningChargeFill color='#46b4e6' />
                            Aulas de hidroginástica;
                        </span>
                    </li>
                    <li>
                        <span>
                            <BsLightningChargeFill color='#46b4e6' />
                            Musculação, treino funcional, circuito e aeroboxe;
                        </span>
                    </li>
                    <li>
                        <span>
                            <BsLightningChargeFill color='#46b4e6' />
                            Avaliações físicas e orientações e consultas
                            nutricionais;
                        </span>
                    </li>
                    <li>
                        <span>
                            <BsLightningChargeFill color='#46b4e6' />
                            Equipe de professores experientes;
                        </span>
                    </li>
                    <li>
                        <span>
                            <BsLightningChargeFill color='#46b4e6' />
                            Infraestrutura diferenciada: piscina semi-olímpica
                            (25 metros);
                        </span>
                    </li>
                    <li>
                        <span>
                            <BsLightningChargeFill color='#46b4e6' />
                            Acessórios para natação que são vendidos na própria
                            Academia.
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
}
