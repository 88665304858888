import styles from './styles.module.scss';

export function SwimmingSchedule() {
    return (
        <section className={styles.cardContainer}>
            <div className={styles.card}>
                <h1 className={styles.cardTitle}>Natação Bebê</h1>
                <div className={styles.cardContent}>
                    <div className={styles.schedule}>
                        <h2>Segunda-feira</h2>
                        <span>10h40 | 11h20 | 16h20 | 17h00</span>
                    </div>
                    <div className={styles.schedule}>
                        <h2>Terça-feira</h2>
                        <span>10h40 | 17h00</span>
                    </div>
                    <div className={styles.schedule}>
                        <h2>Quarta-feira</h2>
                        <span>10h40 | 11h20 | 16h20 | 17h00</span>
                    </div>
                    <div className={styles.schedule}>
                        <h2>Quinta-feira</h2>
                        <span>10h40 | 17h00</span>
                    </div>
                    <div className={styles.schedule}>
                        <h2>Sexta-feira</h2>
                        <span>10h40 | 11h20 | 16h20 | 17h00</span>
                    </div>
                    <div className={styles.schedule}>
                        <h2>Sábado</h2>
                        <span>09h50 | 10h40 | 11h30</span>
                    </div>
                    <span className={styles.alert}>
                        * de 6 meses a 6 anos de idade
                    </span>
                </div>
            </div>
            <div className={styles.card}>
                <h1 className={styles.cardTitle}>Natação</h1>
                <div className={styles.cardContent}>
                    <div className={styles.schedule}>
                        <div className={styles.dayHour}>
                            <h2>Segunda, Quarta & Sexta</h2>
                            <div className={styles.hourGrid}>
                                <span>06h00</span>
                                <span>06h50</span>
                                <span>07h40</span>
                                <span>08h20</span>
                                <span>09h00</span>
                                <span>09h50</span>
                                <span>13h00</span>
                                <span>13h50</span>
                                <span>14h40</span>
                                <span>15h20</span>
                                <span>17h50</span>
                                <span>18h30</span>
                                <span>19h10</span>
                                <span>19h50</span>
                                <span>20h30</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.schedule}>
                        <div className={styles.dayHour}>
                            <h2>Terça-feira & Quinta-feira</h2>
                            <div className={styles.hourGrid}>
                                <span>06h00</span>
                                <span>06h50</span>
                                <span>07h40</span>
                                <span>08h20</span>
                                <span>09h00</span>
                                <span>09h50</span>
                                <span>11h30</span>
                                <span>13h00</span>
                                <span>13h50</span>
                                <span>14h30</span>
                                <span>15h10</span>
                                <span>16h10</span>
                                <span>17h50</span>
                                <span>18h30</span>
                                <span>19h10</span>
                                <span>19h50</span>
                                <span>20h30</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.schedule}>
                        <div className={styles.dayHour}>
                            <h2>Sábado</h2>
                            <div className={styles.hourGrid}>
                                <span>07h00</span>
                                <span>07h40</span>
                                <span>08h20</span>
                                <span>09h00</span>
                                <span>12h20</span>
                                <span>13h00</span>
                                <span>13h40</span>
                                <span>14h20</span>
                                <span>15h00</span>
                            </div>
                        </div>
                    </div>
                    <span className={styles.alert}>* A partir de 7 anos</span>
                </div>
            </div>

            <div className={styles.card}>
                <h1 className={styles.cardTitle}>Hidroginástica</h1>
                <div className={styles.cardContent}>
                    <div className={styles.schedule}>
                        <h2>Segunda, Quarta & Sexta</h2>
                        <span>09h50 | 13h50</span>
                    </div>
                    <div className={styles.schedule}>
                        <h2>Terça & Quinta</h2>
                        <span>09h50 | 15h20 | 19h50</span>
                    </div>
                    <div className={styles.schedule}>
                        <h2>Sábado</h2>
                        <span>Único horário: 13h00</span>
                    </div>
                    <span className={styles.alert}>* A partir de 15 anos</span>
                </div>
            </div>
        </section>
    );
}
