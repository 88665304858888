import { HiOutlineMail, HiOutlinePhone } from "react-icons/hi";
import { BsInstagram, BsWhatsapp } from "react-icons/bs";
import logo from "../../assets/logo-hor.png";

import styles from "./styles.module.scss";

export function Footer() {
    return (
        <footer className={styles.footerContainer}>
            <div className={styles.footerContent}>
                <div className={styles.imgContainer}>
                    <img src={logo} alt='logo' />
                </div>

                <div className={styles.informations}>
                    <div className={styles.content}>
                        <h3>Horário de funcionamento</h3>
                        <span>
                            Segunda à sexta - 06h00 às 21h00
                            <br />
                            Sábado - 07h00 às 16h00
                        </span>
                    </div>
                    <div className={styles.content}>
                        <h3>Redes sociais</h3>
                        <span className={styles.social}>
                            <HiOutlineMail />
                            faleconosco@sema.com.br
                        </span>
                        <span className={styles.social}>
                            <HiOutlinePhone />
                            (11) 2961-1993
                        </span>
                        <span className={styles.social}>
                            <BsInstagram /> Instagram
                        </span>
                        <span className={styles.social}>
                            <BsWhatsapp /> Whatsapp
                        </span>
                    </div>
                    <div className={styles.content}>
                        <h3>Trabalhe conosco</h3>
                        <span>
                            <HiOutlineMail />
                            trabalheconosco@sema.com.br
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    );
}
