import { useEffect } from "react";
import { Others } from "../components/Modalities/Others";
import styles from "./about.module.scss";

export function Hidro() {
    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }, []);
    return (
        <main className={styles.main}>
            <article>
                <h1 className={styles.title}>Hidroginástica</h1>
                <span className={styles.subtitle}>
                    a partir dos 15 anos - 50 minutos de aula
                </span>
                <img
                    src='https://cepenha.com.br/wp-content/uploads/2019/05/img2.jpg'
                    alt='musculação'
                    className={styles.banner}
                />
                <p>
                    Uma modalidade física onde a ausência da gravidade diminui o
                    risco de contusão, proporcionando bem-estar ao praticante. A
                    Hidroginástica é a principal modalidade para terceira idade
                    e pessoas em recuperação motora.
                </p>
                <p>
                    Os movimentos realizados pelos alunos são feitos justamente
                    contra a força exercida pela água que é de baixo para cima.
                    Essa forma de sobrecarga pode contar com materiais para
                    aumentar a resistência, como halter, aquatubo ou macarrão,
                    caneleira, luva, colete, bola, prancha ou qualquer outro
                    material aquático. A música é o fator principal durante a
                    atividade, já que os alunos estão lá muitas vezes para
                    socializar e se divertir. O ritmo proporciona o estímulo
                    necessário para os praticantes.
                </p>
                <p>
                    Os benefícios da hidroginástica são muitos porque incluem
                    todos aqueles de atividades físicas em meio terrestre
                    (aeróbios e localizados), aliados ao impacto quase
                    inexistente sobre as articulações. Apresenta também melhoras
                    para os diversos males como a constipação, depressão,
                    estresse, insônia, osteoporose, pressão alta e senilidade.
                    Então se apresenta como uma ótima possibilidade de prática,
                    não apenas para os idosos, mas sim para todas as pessoas. O
                    gasto calórico médio é de 260 a 400 Kcal/hora.
                </p>
                <h3>Principais benefícios</h3>
                <ul>
                    <li>
                        Exercícios aeróbicos e cardiovasculares realizados em
                        ambiente aquático acompanhado de música.
                    </li>
                    <li>Turmas de todas as idades.</li>
                    <li>
                        Trabalha o fortalecimento muscular e contribui para a
                        diminuição do percentual de gordura.
                    </li>
                    <li>
                        Melhora o sistema cardiovascular: o trabalho na aula de
                        hidroginástica auxilia no condicionamento por ter um
                        aumento do retorno venoso (maior volume de sangue
                        voltando ao coração para ser bombeado). - Até por esse
                        motivo, ela é indicada por cardiologistas para pessoas
                        hipertensas ou com outros problemas cardíacos.
                    </li>
                    <li>
                        Auxilia na drenagem linfática, reduzindo a retenção de
                        líquidos e sendo grande aliada do emagrecimento.
                    </li>
                    <li>
                        Melhora a flexibilidade: a redução da força
                        gravitacional na água proporciona uma maior e melhor
                        flexibilidade do corpo.
                    </li>
                    <li>Melhora a qualidade de vida</li>
                    <li>Melhora a qualidade do sono</li>
                    <li>Relaxa o corpo após um dia repleto de atividades.</li>
                </ul>
            </article>

            <Others />
        </main>
    );
}
