import { useEffect } from "react";
import bannerImg from "../assets/funcional2.jpeg";
import { Others } from "../components/Modalities/Others";
import styles from "./about.module.scss";

export function Funcional() {
    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }, []);
    return (
        <main className={styles.main}>
            <article>
                <h1 className={styles.title}>Funcional</h1>
                <img
                    src={bannerImg}
                    alt='musculação'
                    className={styles.banner}
                />
                <p>
                    O Treinamento Funcional tem como seu principal foco melhorar
                    o funcionamento do seu corpo no cotidiano. São exercícios
                    que associam os movimentos naturais do dia a dia que
                    integrados com a musculação de forma eficiente proporcionam
                    benefícios ímpares como: ganho de força e potência; aumento
                    da massa muscular, aumento de resistência, maior equilíbrio
                    e acelera o processo de emagrecimento.
                </p>
                <h3>Quais os benefícios do treino funcional?</h3>
                <ul>
                    <li>Fortalecimento dos músculos e melhora da postura;</li>
                    <li>Aumento na capacidade cardiorrespiratória;</li>
                    <li>Ajuda no emagrecimento;</li>
                    <li>Diminuição dos sintomas de depressão;</li>
                    <li>Atividade dinâmica que melhora o astral;</li>
                    <li>Boa relação tempo x resultados.</li>
                </ul>
            </article>

            <Others />
        </main>
    );
}
