import { Link, useLocation } from "react-router-dom";

export function Links({ closeMenu }) {
    const location = useLocation().pathname;

    async function scrollToView(id) {
        closeMenu(false);
        const el = document.getElementById(id);
        el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
        });
    }

    return (
        <ul>
            {location === "/" ? (
                <>
                    <li onClick={() => scrollToView("modalities")}>
                        Modalidades
                    </li>
                    <li onClick={() => scrollToView("location")}>
                        Localização
                    </li>
                    <li onClick={() => scrollToView("contact")}>Contato</li>
                </>
            ) : (
                <>
                    <li>
                        <Link to='/musculacao'>Musculação</Link>
                    </li>
                    <li>
                        <Link to='/natacao'>Natação</Link>
                    </li>
                    <li>
                        <Link to='/circuito'>Circuito</Link>
                    </li>
                    <li>
                        <Link to='/hidro'>Hidroginástica</Link>
                    </li>
                    <li>
                        <Link to='/funcional'>Funcional</Link>
                    </li>
                    <li>
                        <Link to='/aerobox'>Aeroboxe</Link>
                    </li>
                </>
            )}
        </ul>
    );
}
