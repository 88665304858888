import { useRef } from 'react';
import { HiOutlineMail } from 'react-icons/hi';
import { FaUserAlt } from 'react-icons/fa';
import emailjs from '@emailjs/browser';

import styles from './styles.module.scss';

export function ContactUs() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                process.env.REACT_APP_EMAIL_SERVICE_ID,
                process.env.REACT_APP_EMAIL_TEMPLATE,
                form.current,
                process.env.REACT_APP_EMAIL_PUBLIC_KEY
            )
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error.text);
                }
            );

        e.target.reset();
    };

    return (
        <form
            ref={form}
            className={styles.form}
            autoComplete='off'
            onSubmit={sendEmail}
        >
            <div className={styles.inputRow}>
                <div className={styles.inputContainer}>
                    <label htmlFor='name'>Nome completo</label>
                    <div>
                        <input
                            type='text'
                            name='user_name'
                            id='name'
                            required
                            placeholder='Digite seu nome...'
                        />
                        <FaUserAlt size={22} />
                    </div>
                </div>
                <div className={styles.inputContainer}>
                    <label htmlFor='email'>Email</label>
                    <div>
                        <input
                            type='email'
                            name='user_email'
                            id='email'
                            required
                            placeholder='Digite seu email...'
                        />
                        <HiOutlineMail size={25} />
                    </div>
                </div>
            </div>
            <div className={styles.textareaRow}>
                <label htmlFor='message'>Mensagem</label>
                <textarea
                    name='message'
                    id='message'
                    autoComplete='off'
                    placeholder='Digite sua mensagem aqui...'
                    rows='8'
                    maxLength='400'
                />
            </div>
            <button type='submit' className={styles.formButton}>
                Enviar mensagem
            </button>
        </form>
    );
}
