import { useEffect } from "react";
import bannerImg from "../assets/circuito2.jpeg";
import { Others } from "../components/Modalities/Others";
import styles from "./about.module.scss";

export function Circuito() {
    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }, []);
    return (
        <main className={styles.main}>
            <article>
                <h1 className={styles.title}>Circuito</h1>
                <img
                    src={bannerImg}
                    alt='musculação'
                    className={styles.banner}
                />
                <p>
                    Uma modalidade criada com base em movimentos simples, que
                    não precisam, necessariamente, de aparelhos ou pesos,
                    podendo ser feitos somente com o peso do corpo. Os
                    movimentos de empurrar, agachar, correr, saltar, puxar e
                    lançar são combinados na criação de exercícios aeróbicos, de
                    estabilidade ou de força.
                </p>
                <br />
                <p>
                    No circuito - como o próprio nome diz -, os educadores
                    físicos/professores criam um circuito por aula agrupando
                    exercícios de acordo com a proposta do aluno ou do grupo,
                    trabalhando o corpo de maneira global ou fortalecendo grupos
                    musculares isolados. Tudo com foco na melhora da performance
                    e na evolução constante, com o cuidado de prevenir lesões e
                    desgastes desnecessários.
                </p>
                <br />
                <p>
                    Nessa atividade, podem ser trabalhados grupos musculares que
                    normalmente não são acionados em outras modalidades. Os
                    exercícios que compõem os circuitos são multiarticulares e
                    multiplanares. Desse modo, acionam funções para as quais o
                    corpo humano foi feito, mas que acabaram sendo esquecidas em
                    função da redução de mobilidade no nosso dia a dia.
                </p>
                <br />
                <p>
                    Assim, o circuito pode servir também como reabilitação, para
                    que o aluno se condicionar fisicamente para praticar outros
                    esportes.
                </p>
                <br />
                <h3>Benefícios do circuito</h3>
                <ul>
                    <li>Melhora do rendimento nos esportes;</li>
                    <li>Alto gasto calórico, que ajuda no emagrecimento; </li>
                    <li>
                        Aprimoramento físico (de resistência, força e
                        coordenação motora) para as atividades cotidianas;
                    </li>
                    <li>Fortalecimento e resistência muscular;</li>
                    <li>
                        Melhora da flexibilidade, da agilidade e do equilíbrio;
                    </li>
                    <li>Melhora da postura;</li>
                    <li>Melhora da capacidade cardiorrespiratória;</li>
                    <li>Aumento da consciência corporal;</li>
                    <li>Menor risco de lesões.</li>
                </ul>
            </article>

            <Others />
        </main>
    );
}
