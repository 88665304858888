import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import useWindowSize from "../../../hooks/useWindowSize";

import aeroboxImg from "../../../assets/aerobox.jpg";
import circuitoImg from "../../../assets/circuito.jpg";
import funcionalImg from "../../../assets/funcional.jpg";
import hidroImg from "../../../assets/hidro.jpg";
import musculacaoImg from "../../../assets/musculacao.jpg";
import natacaoImg from "../../../assets/natacao.jpg";

import "swiper/scss";
import "swiper/scss/pagination";

import styles from "./styles.module.scss";
import { useEffect } from "react";
import { useState } from "react";

export const Others = () => {
    const history = useNavigate();
    const { width } = useWindowSize();
    const [slidesToShow, setSlidesToShow] = useState(3);

    const handleChangePage = (page) => {
        history(`/${page}`);
    };

    useEffect(() => {
        console.log(width)
        if (width <= 500) {
            setSlidesToShow(1);
        } else if (width > 500 && width < 800) {
            setSlidesToShow(3);
        } else {
            setSlidesToShow(4);
        }
    }, [width]);

    return (
        <div className={styles.sliderContainer}>
            <h3>Outras modalidades</h3>
            <Swiper
                slidesPerView={slidesToShow}
                spaceBetween={20}
                watchSlidesProgress={true}
            >
                <SwiperSlide
                    className={styles.slide}
                    onClick={() => handleChangePage("aerobox")}
                >
                    <img src={aeroboxImg} alt='Aeroboxing' />
                    <div className={styles.info}>
                        <h1>Aeroboxe</h1>
                        <p>
                            O intenso trabalho aeróbico obtido através deste
                            exercício permite não só um aumento...
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide
                    className={styles.slide}
                    onClick={() => handleChangePage("circuito")}
                >
                    <img src={circuitoImg} alt='Circuito' />
                    <div className={styles.info}>
                        <h1>Circuito</h1>
                        <p>
                            Uma modalidade criada com base em movimentos
                            simples, que não precisam, necessariamente...
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide
                    className={styles.slide}
                    onClick={() => handleChangePage("funcional")}
                >
                    <img src={funcionalImg} alt='Funcional' />
                    <div className={styles.info}>
                        <h1>Funcional</h1>
                        <p>
                            O Treinamento Funcional tem como seu principal foco
                            melhorar o funcionamento do seu corpo...
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide
                    className={styles.slide}
                    onClick={() => handleChangePage("hidro")}
                >
                    <img src={hidroImg} alt='Hidroginástica' />
                    <div className={styles.info}>
                        <h1>Hidroginástica</h1>
                        <p>
                            Uma modalidade física onde a ausência da gravidade
                            diminui o risco de contusão...
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide
                    className={styles.slide}
                    onClick={() => handleChangePage("musculacao")}
                >
                    <img src={musculacaoImg} alt='Musculação' />
                    <div className={styles.info}>
                        <h1>Musculação</h1>
                        <p>
                            realizado com pesos de diversas cargas, amplitude e
                            tempo de contração, o que faz dela uma...
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide
                    className={styles.slide}
                    onClick={() => handleChangePage("natacao")}
                >
                    <img src={natacaoImg} alt='Natação' />
                    <div className={styles.info}>
                        <h1>Natação</h1>
                        <p>
                            Além do condicionamento físico, a natação traz
                            diversos benefícios para a saúde, possuindo....
                        </p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};
