import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import { Links } from "./links";
import { Drawer } from "./drawer";

import logoImg from "../../assets/logo-header.png";
import styles from "./styles.module.scss";

export function Header() {
    const [activeDrawer, setActiveDrawer] = useState(false);

    return (
        <header className={styles.headerContainer}>
            <div className={styles.headerContent}>
                <Link to='/' className={styles.linkHeader}>
                    <img
                        src={logoImg}
                        alt='logo'
                        style={{ width: "175px", height: "100%" }}
                    />
                </Link>
                <nav>
                    <Links closeMenu={setActiveDrawer} />
                </nav>
                <button
                    className={styles.hamburgerButton}
                    onClick={() => setActiveDrawer(true)}
                >
                    <GiHamburgerMenu color='#FFF' size='2em' />
                </button>
            </div>
            <Drawer isActive={activeDrawer} togglerDrawer={setActiveDrawer} />
        </header>
    );
}
