import { Links } from './links';
import { IoMdClose } from 'react-icons/io';

import styles from './styles.module.scss';

export function Drawer({ isActive, togglerDrawer }) {
    return (
        <div
            className={`${styles.drawerContainer} ${
                isActive ? styles.active : ''
            }`}
        >
            <div className={styles.logo}>
                <h1>SEMA</h1>
                <button onClick={() => togglerDrawer(false)}>
                    <IoMdClose color='#FFF' size='2rem' />
                </button>
            </div>
            <Links closeMenu={togglerDrawer} />
        </div>
    );
}
