import { Link } from "react-router-dom";
import hidro from "../../assets/hidro.jpg";
import aerobox from "../../assets/aerobox.jpg";
import natacao from "../../assets/natacao.jpg";
import circuito from "../../assets/circuito.jpg";
import funcional from "../../assets/funcional.jpg";
import musculacao from "../../assets/musculacao.jpg";

import styles from "./styles.module.scss";

export function Modalities() {
    return (
        <section className={styles.sectionContainer} id='modalities'>
            <h1>
                <span>Nossas</span> Modalidades
            </h1>

            <div className={styles.sectionCards}>
                <Link to='/musculacao'>
                    <div className={styles.card}>
                        <img src={musculacao} alt='Musculação' />
                        <div className={styles.info}>
                            <span>Musculação</span>
                            <span className={styles.knowMore}>
                                Clique e saiba mais
                            </span>
                        </div>
                    </div>
                </Link>

                <Link to='/natacao'>
                    <div className={styles.card}>
                        <img src={natacao} alt='Natação' />
                        <div className={styles.info}>
                            <span>Natação</span>
                            <span className={styles.knowMore}>
                                Clique e saiba mais
                            </span>
                        </div>
                    </div>
                </Link>

                <Link to='/circuito'>
                    <div className={styles.card}>
                        <img src={circuito} alt='Circuito' />
                        <div className={styles.info}>
                            <span>Circuito</span>
                            <span className={styles.knowMore}>
                                Clique e saiba mais
                            </span>
                        </div>
                    </div>
                </Link>

                <Link to='/hidro'>
                    <div className={styles.card}>
                        <img src={hidro} alt='Hidroginástica' />
                        <div className={styles.info}>
                            <span>Hidroginástica</span>
                            <span className={styles.knowMore}>
                                Clique e saiba mais
                            </span>
                        </div>
                    </div>
                </Link>

                <Link to='/funcional'>
                    <div className={styles.card}>
                        <img src={funcional} alt='Funcional' />
                        <div className={styles.info}>
                            <span>Funcional</span>
                            <span className={styles.knowMore}>
                                Clique e saiba mais
                            </span>
                        </div>
                    </div>
                </Link>

                <Link to='/aerobox'>
                    <div className={styles.card}>
                        <img src={aerobox} alt='aerobox' />
                        <div className={styles.info}>
                            <span>aeroboxe</span>
                            <span className={styles.knowMore}>
                                Clique e saiba mais
                            </span>
                        </div>
                    </div>
                </Link>
            </div>
        </section>
    );
}
