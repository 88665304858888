import { BsInstagram, BsWhatsapp } from 'react-icons/bs';
import { HiOutlineRss } from 'react-icons/hi';
import { About } from './about';

import gympass from '../../assets/gympass.png';
import totalpass from '../../assets/totalpass.png';

import styles from './styles.module.scss';

export function Integrations() {
    return (
        <section className={styles.integrationsContainer}>
            <About />
            <div className={styles.integrationsContent}>
                <div className={styles.leftContent}>
                    <h1>
                        A <span>SEMA</span> conectada <br /> com{' '}
                        <span>
                            {' '}
                            você <HiOutlineRss />
                        </span>
                    </h1>
                    <div className={styles.social}>
                        <div>
                            <a
                                href='https://www.instagram.com/academiasema/'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <BsInstagram /> academiasema
                            </a>
                        </div>
                        <div>
                            <a
                                href='https://wa.me/message/QSGCO5BMJAROP1'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <BsWhatsapp /> Whatsapp
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles.rightContent}>
                    <div>
                        <img src={gympass} alt='gympass' />
                    </div>
                    <div>
                        <img src={totalpass} alt='totalpass' />
                    </div>
                </div>
            </div>
        </section>
    );
}
